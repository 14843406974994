.user-profile {
  color: var(--bs-gray-600);
  border: 0.0625rem solid $border-color;
  border-radius: 0.5rem;
  position: relative;

  .dropdown {
    padding: 0 0.8rem;

    .user {
      &::after {
        right: 0;
        display: none;

        @include min-break-point(tablet-landscape) {
          display: block;
        }
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .user {
    
    @include min-break-point(tablet-landscape) {
      min-width: 11.5em;
    }

    img {
      border: 1px solid rgba(var(--bs-btn-bg), 100%);
      box-sizing: border-box;
      width: 2.25em;
      height: 2.25em;
      border-radius: 50%;
      margin-top: 0.2em;

      @include min-break-point(tablet-landscape) {
        margin-right: 0.5em;
      }
    }

    .name {
      text-transform: capitalize;
      margin-top: -0.5em;
    }
  }

  ul {
    width: 100%;
    border: 1px solid var(--bs-gray-200);
    width: fit-content;

    .home-container {
      margin-left: -1.2em;
    }
  }

  .profile-list {
    top: calc(100% + 0.375rem) !important;
    transform: none !important;
    li {
      color: var(--bs-gray-600);
      &:hover {
        background: transparent;
      }
      a {
        color: var(--bs-gray-600);
      }

      &:hover {
        color: $primary-color;

        a {
          color: $primary-color;
        }
      }

      margin-top: 0.4em;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}

.image-holder {
  @include size(2.125rem);

  min-width: 2.125rem;
  line-height: 2.125rem;
  background-color: rgba(var(--bs-body-rgb-2), 100%) !important;
  font-size: 70%;
}
