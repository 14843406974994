.finished {
  width: 100%;

  @include min-break-point(tablet) {
    width: 90%;
  }

  @include min-break-point(tablet-landscape) {
    width: 70%;
  }

  .title {
    font-weight: $font-weight-bold;
    font-size: $h3-font-size;
    line-height: 1.3em;
  }
}
