.accordion {
  background-color: transparent;

  @extend .rounded-3;

  &-item {
    @extend .mb-3;
    background: none;
    border-radius: 0.5rem !important;
    overflow: hidden;
  }

  &-header {
    border-radius: 0;
    background: none;
  }

  &-button {
    background-color: $border-color;
    color: var(--bs-gray-800);
    border-radius: 0.5rem !important;
    box-shadow: none !important;

    &::after {
      @extend .d-none;
    }

    &:not(.collapsed) {
      background-color: $border-color;
      color: var(--bs-gray-800);
    }

    &.collapsed {
      // background-color: var(--bs-bg-color-2);
      background-color: $bg-body;
      color: var(--bs-gray-800);
      border-radius: 0.5rem 0.5rem 0 0 !important;

      .accordion-icon {
        transform: rotate(0);
      }
    }
  }
  &-icon {
    transition: 0.2s all ease-in-out;
    transform: rotate(180deg);
    svg {
      path {
        stroke: var(--bs-secondary);
      }
    }
  }

  &-item {
    @extend .border-0;
  }

  &-body {
    background-color: var(--bs-bg-color-1);
  }

  .__react_component_tooltip.place-top {
    filter: brightness(1.4);
    color: #fff;
  }

  .accordion-button:not(.collapsed)::after {
    // background-image: url(../../images/arrowUp.svg);
    transition: transform 0.3s ease-in-out;
    transform: rotate(-180deg);
    background-size: auto;
  }

  .accordion-button::after {
    // background-image: url(../../images/arrowDown.svg);
    transition: transform 0.5s ease-in-out;
    transform: rotate(180deg);
    background-size: auto;
    top: 20%;
    right: 2%;
    position: absolute;
  }

  .wrapper {
    background-color: var(--bs-bg-color-2);
    @extend .rounded-2, .overflow-hidden;

    img {
      @include size(100%);
    }
  }

  .mask {
    filter: brightness(50%);
  }

  .action {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 2.5em;
    }

    .info-icon {
      svg {
        @include size(2.75em);
        path {
          stroke: $text-color;
          fill: $text-color;
        }
      }
    }
  }

  .desc {
    padding: 0.875em;
  }

  .title {
    h4 {
      font-size: 1em;
      font-weight: $font-weight-bold;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.status {
  border-radius: $border-radius-sm;
  height: 0.75em;

  &.btn {
    height: auto;
  }

  .progress-bar {
    height: 100%;
    border-radius: $border-radius-sm;
    background-color: $primary-color;
  }

  .progress-text {
    font-size: 0.563em;
    color: $black;
  }
}

.progress-height {
  height: 0.375em;
}

.enroll-btn {
  height: 2em;
  transition: 0.2s ease;
  color: $secondary-color;
  border: 0.063em solid $secondary-color;
  border-radius: $border-radius-sm;

  .round {
    border-radius: 0.625em;
  }

  .see-less {
    font-size: 0.875em;
  }

  .enroll-btn {
    height: 2em;
    transition: 0.2s ease;
    background-color: transparent;
    color: $secondary-color;
    border: 0.063em solid $secondary-color;
    border-radius: $border-radius-sm;

    &:hover {
      background-color: $secondary-color;
      color: $black;
    }
  }

  .content-link {
    transition: 0.2s ease;

    &:hover {
      text-decoration: underline;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }
}
