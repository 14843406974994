.pattern-container {
  pointer-events: none;

  .first-pattern {
    @include user-select(none);

    width: 11em;
    height: 13em;
    top: -2em;
    left: -6em;
    pointer-events: none;
  }
  .second-pattern {
    @include user-select(none);

    width: 13em;
    height: 13.8em;
    top: -1.2em;
    left: -4.2em;
    pointer-events: none;
  }
  .third-pattern {
    @include user-select(none);

    width: 12em;
    height: 18em;
    opacity: 0.3;
    top: -1em;
    left: -1.3em;
    pointer-events: none;
  }
}

.desktop-pattern {
  bottom: -3.2em;
  right: -3.1em;
  z-index: -1;
}
