.game-mode {
  .title {
    font-weight: $font-weight-bold;
    font-size: $h5-font-size;
    @include max-break-point(mobile-phone) {
      font-size: 0.8em;
    }
  }

  .percentage {
    color: var(--bs-gray-600);
   
    line-height: 1.25em;
  }
}
