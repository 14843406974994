.sign-in {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  .top-circle {
    position: absolute;
    top: -70%;
    left: -30%;
    z-index: -1;
  }

  .bottom-circle {
    position: absolute;
    bottom: -70%;
    right: -30%;
    z-index: -1;
  }

  .bg-image {
    position: relative;

    .bg {
      img {
        width: 100%;
        height: 100%;
        border-radius: $border-radius;
      }
    }

    .create-account {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      a {
        font-size: 0.8em;
        color: $white;
        margin: 0;
        border: 0.063em solid $white;
        border-radius: $border-radius;
        padding: 0.8em 1em;
        transition: 0.2s ease;

        &:hover {
          background-color: $primary-color;
          border: 0.063em solid $primary-color;
        }
      }
    }
  }

  .login-form {
    h2 {
      font-size: 1.25em;
      color: var(--bs-gray-900);
      font-weight: $font-weight-bold;
    }

    .form-container {
      .form {
        width: 100%;
        .login-attempts {
          strong {
            font-size: 0.875em;
            font-weight: $font-weight-semibold;
            line-height: 1.375em;
          }

          p {
            font-size: 0.875em;
            line-height: 1.375em;
          }
        }

        .input-field {
          position: relative;


          .icon {
            position: absolute;
            right: 2%;
            top: 20%;

            img {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .btn-link {
    font-size: 0.938em;
    text-decoration: none;
    transition: 0.2s ease;

    &:hover {
      color: rgba(var(--text-primary-rgb), 60%) !important;
    }
  }


}
