.overlay {
  backdrop-filter: blur(1.063em);
  border-radius: 0;
  inset: 0;
  z-index: 99;
}

.custom-modal {
  border-radius: 0.625em;
  border-radius: 0;

  .modal-header {
    border-bottom: 0;

    .title {
      font-size: 1.375rem;
      font-weight: 500;
    }
  }
}

