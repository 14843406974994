.talent-pool-graph {
  overflow: hidden;

  .blank-block {
    background-color: rgba(var(--bs-body-rgb-1), 100%);
    height: 4.5em;
  }

  &-table {
    overflow: hidden;
    border: 0.125em solid $border-color;
    border-radius: $border-radius;
    table {
      border-spacing: 0;
      border-style: hidden;
    }
  }

  .table-header {
    background-color: var(--bs-bg-color-2);
    height: 2.5em;

    th {
      border-left: 0.125em solid $border-color;
      border-bottom: 0.125em solid $border-color;
    }
  }

  .cell-header {
    vertical-align: middle;
    color: $text-color;
  }

  &-table {
    border-radius: $border-radius;
  }

  .block-container {
    height: 12em;
  }

  .talent-pool-block {
    bottom: 0;
    position: absolute;
    top: 0;

    @include min-break-point(tablet) {
      width: 14em;
    }

    &-title {
      color: $text-color;
      font-size: $h4-font-size;
      font-weight: $font-weight-bold;
    }

    &-description {
      color: $text-color;
      font-size: $mini-font-size;
      line-height: 0.9375rem;
    }

    &.watch-out {
      background-color: var(--bs-danger);
    }

    &.most-applicant {
      background-color: var(--bs-bg-color-2);
    }

    &.stand-out {
      background-color: $primary-color;
    }
  }

  .talent-pool-graph-table {
    @include max-break-point(large-desktop) {
      overflow: auto;
    }
  }

  // stylelint-disable max-nesting-depth

  table {
    background-color: rgba(var(--bs-body-rgb-1), 100%);
    border-color: $border-color;
    min-width: 60em;

    td,
    th {
      border-left: 0.125em solid $border-color;
      &:first-child {
        width: 10em;

        @include min-break-point(tablet) {
          width: 14em;
        }
      }
    }
  }
  // stylelint-enable max-nesting-depth
}

.talent-pool-bar-container {
  .talent-pool-bar {
    @include size(0.4em, 80%);

    &.watch-out {
      background-color: var(--bs-danger);
    }

    &.most-applicant {
      background-color: var(--bs-gray-600);
    }

    &.stand-out {
      background-color: $primary-color;
    }
  }

  .bar-tooltip {
    background-color: var(--bs-bg-color-2);
    border-radius: 0.1875em;
    border-radius: 0.3875em;
    left: 50%;
    top: 1.8em;
    width: fit-content;

    &::after {
      border-color: transparent transparent var(--bs-bg-color-2) transparent;
      border-style: solid;
      border-width: 0.3125em;
      bottom: 100%;
      content: "";
      left: 50%;
      margin-left: -0.3125em;
      position: absolute;
    }

    .bar-name {
      font-size: $span-font-size;
      color: $text-color;
    }
  }
}
