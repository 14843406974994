.radio-groups {
  &.inline {
    .label-container {
      &:focus {
        color: var(--bs-gray-600) !important;
      }
    }
  }
}

.extra-form {
  @extend .p-0;

  .common-wrapper {
    padding: 2.1875em;
  }

  h2,
  h3,
  p {
    @extend .mb-3;
    font-size: $font-size-base;

    &.mb-0 {
      @extend .mb-0;
    }
  }

  p {
    line-height: 1.625;
  }

  h3 {
    span {
      font-weight: $font-weight-normal;
    }
  }

  strong {
    @extend .text-uppercase;

    color: var(--bs-gray-900) !important;
    font-weight: $font-weight-bold;
  }
}

.extra-form-wrapper {
  h2 {
    font-size: $font-size-base;
  }
}

.disability-list {
  grid-template-columns: auto auto auto auto;
}

.pseudo-contained {
  padding-left: 1.5rem;

  &::before {
    @extend .position-absolute, .rounded-pill, .start-0;

    content: "";
    top: 0.4rem;
    width: 0.625em;
    height: 0.625em;
    background: var(--bs-gray-600);
  }
}
