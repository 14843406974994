.job-modal {
  .modal-content {
    background-color: var(--bs-bg-color-1);
    color: var(--bs-gray-500);
    padding: 2em 1em;
    border-radius: $border-radius;

    .card-item {
      transition: 0.2s ease;
      padding: 1em;
      border: 0.1em solid var(--bs-bg-color-1);
      border-radius: $border-radius;
      background-color: var(--bs-bg-color-2);

      .left {
        flex: 0.1;
      }

      .info-wrapper {
        flex: 0.9;
        gap: 0.25em;

        .title {
          font-size: 0.875em;
          font-weight: $font-weight-bold;
          color: $primary-color;
        }

        div {
          font-size: 0.75em;

          .status {
            span {
              color: $primary-color;
            }
          }
        }
      }

      &:hover {
        border: 0.1em solid $primary-color;
      }
    }

    .bVoXXg {
      height: 100%;
    }

    .bghjHX {
      margin: 0 !important;
    }

    .kVtMtA,
    .bhRnqO {
      background: $primary-color;
      opacity: 1;
      transition: all 0.3s ease-in-out;
      &:hover {
        filter: brightness(1.1);
      }
    }

    .kVtMtA {
      left: -7%;
    }

    .bhRnqO {
      right: -7%;
    }

    .kVtMtA::before,
    .bhRnqO::before {
      border-top: 0.2em solid var(--bs-bg-color-1);
      border-right: 0.2em solid var(--bs-bg-color-1);
    }
  }
}
