.dropdown {
  &-toggle {
    border-radius: 0.625em;
    min-width: 4em;
    border: 1px solid var(--bs-gray-500);

    &.btn-secondary-link {
      color: var(--bs-gray-900) !important;
    }

    &.icon-added {
      padding-left: 3.5em;
    }

    &::after {
      top: 45%;
      transform: translateX(-45%);
      right: 1%;
      position: absolute;
      color: rgba(var(--bs-headings), 80%);
    }

    &:hover,
    &:active,
    &:focus,
    &.show {
      background-color: transparent;
      color: var(--bs-gray-600);
      outline: none;
      box-shadow: none;
    }
  }

  .dropdown-menu {
    padding: 0.5em 0;
    min-width: 100%;
    background-color: $bg-body;
    top: 3.25rem;
    border: 0.0625rem solid $border-color;

    &::-webkit-scrollbar {
      width: 0.2em;
    }

    .dropdown-item {
      color: $text-color;
      font-size: $h5-font-size;
      padding: 0.4em 1em;
      cursor: pointer;

      &:hover,
      &:focus,
      &.active {
        color: $primary-color;
        background: none;
      }

      .active {
        font-weight: 700;
      }
    }

    li {
      &:hover {
        background: $bg-secondary-body;
      }
    }
  }
}
