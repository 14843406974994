.noJob-wrapper {
  border-radius: 0.625em;

  .title {
    color: var(--bs-gray-500);
    font-size: $title-size;
    font-weight: $font-weight-semibold;
  }

  .desc {
    color: var(--bs-gray-600);
  }
}

.location-values {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 18.75em;
}

.job-details-values {
  @extend .location-values;

  width: 14.375em;
}
