.dropdown-toggle {
  border: 0.0625em solid var(--bs-gray-400);
  background: none;
  color: var(--bs-gray-600);
  height: 3rem;
  border-radius: 0.625em;

  &::placeholder {
    color: var(--bs-gray-600);
  }

  &:focus,
  &:hover &:active {
    outline: none;
    border: 0.0725em solid var(--bs-gray-600);
    background: none;
    color: var(--bs-gray-600);
  }

  &:focus ~ label {
    background-color: rgba(var(--bs-body-rgb-2), 100%);
    color: var(--bs-gray-600);
  }
}

.checkbox {
  width: 1.2em;
  height: 1.2em;
  margin-top: 0.1em;
  margin-right: 0.4em;
  margin-left: 0.002em;

  &:checked {
    background-color: rgba(var(--bs-primary-rgb), 100);
    border-color: var(--bs-bg-color-1);
  }
}

.dropdown .dropdown-toggle::after {
  content: url(../../images/chevronDown.svg);
  top: 50%;
  height: 24px;
  width: 24px;
  transform: translateY(-50%);
  right: 10px;
  border: none;
}

.dropdown .onboarding.dropdown-toggle::after {
  content: "";
}

.dropdown-item {
  border-bottom: 0.0625em solid var(--primary-border-color);
}

span {
  color: var(--bs-heading-color);

  // font-weight: $font-weight-bold;
}

.base-position {
  bottom: 0.425em;
  margin-bottom: -0.2em;
  z-index: 1;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}

label {
  // bottom: 0.425em;
  // left: 0.93em;
  color: var(--bs-gray-600);
  padding: 0 0.31em;
  transition: 0.2s;
  z-index: 1;
  // pointer-events: none;

  &.dropdown-label {
    padding: 0;
    // bottom: 0.425em;
    left: 0.93em;
    top: -0.5rem;
    font-size: $span-font-size;
    color: var(--bs-gray-600);
    height: 1rem;
    display: inline;
    background-color: $bg-secondary-body;
  }

  &.icon-added {
    left: 3em;
  }

  &.multi-select-label {
    bottom: 0.5em;
    z-index: 1;
  }

  &.value-added {
    bottom: 2.2em;
    z-index: 2;
    height: 1.3em;
  }
}
.label-text {
  @extend .ps-2;
}

.label-text ~ label {
  bottom: 2.125em;
  transition: 0.2s;

  &.value-added {
    bottom: 3.5em;
    z-index: 2;
    height: 1.3em;
  }
}

button {
  &:focus {
    box-shadow: none;
  }
}

*:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-body-rgb-2), 20%) !important;
}

.form-text {
  color: $secondary-color;
  font-weight: $font-weight-bold;
  color: var(--bs-gray-600);

  &.form-dropdown {
    min-height: 1.5em;
  }
}

.label-container {
  input {
    @extend .position-absolute, .opacity-0;
    left: 0;
    height: 1.5625rem;
    width: 1.5625rem;
    cursor: pointer;
    z-index: 100;

    &:checked {
      ~ .checkmark {
        background-color: transparent;
        border-color: $primary-color;

        &::after {
          @extend .d-block;
        }
      }

      ~ .label-text {
        color: var(--bs-gray-900);
      }
    }
  }

  &:hover {
    input {
      ~ .checkmark {
        background-color: transparent;
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $bg-secondary-body inset !important;

  // to counter the transparent change
  transition: all 0s 50000s;

  // color: $white;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: $text-color !important;
}

div.react-tel-input {
  @extend .w-100;
  position: relative;

  .form-control {
    @extend .w-100;
    height: 3rem;
    background-color: transparent;
    border: 0.0625rem solid var(--bs-gray-400);
    border-radius: $border-radius;
    color: var(--bs-gray-800);
    font-size: $font-size-sm;
    line-height: 1.2;

    &:focus {
      box-shadow: none;
      border-color: rgba(var(--text-secondary-rgb), 60%);
      color: var(--bs-gray-800);
    }

    &:focus,
    &:hover &:active {
      outline: none;
      background: transparent;
    }

    @include input_placeholder {
      color: var(--bs-gray-400);
    }
  }

  .selected-flag .arrow {
    border-top-color: var(--bs-gray-800);
  }

  .selected-flag .arrow.up {
    border-bottom-color: var(--bs-gray-800);
  }

  .country-list {
    background: var(--bs-bg-color-1);

    .country {
      &:hover,
      &.highlight {
        background: var(--bs-bg-color-2);
      }

      &.highlight {
        text-decoration: underline;
      }

      .country-name,
      .dial-code {
        color: var(--bs-gray-800);
      }
    }
  }
}

.phone.form-floating label {
  position: absolute;
  padding: 0 0.25rem;
  background-color: var(--bs-bg-color-2);
  opacity: 1;
  height: 1.5rem;
  width: auto;
  left: 0.875rem;
  top: -0.1875rem;
  display: inline-block;
  font-size: 0.875rem;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
