.tooltiptext {
  &.position-absolute {
    min-width: 8.75em;
    top: 1em;
    opacity: 0;
    transition: opacity 400ms ease;
    z-index: 1;
    h4 {
      font-size: 0.875em;
    }
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: -0.875em;
    right: 1.1875em;
    border: 0.4375em solid transparent;
    border-bottom: 0.4375em solid $primary-color;
  }
  &::before {
    top: -0.75em;
    right: 1.25em;
    border-width: 0.375em !important;
    border-bottom-color: rgba(var(--bs-body-rgb-2), 100%);
    z-index: 1;
  }
  li {
    font-size: 0.75em;
  }
}

.custom-tooltip {
  hr {
    background-color: rgba(var(--bs-primary-rgb), 0.8);
  }
  .percentage-holder:hover {
    cursor: pointer;
    & + .tooltiptext {
      opacity: 1;
    }
  }
}
