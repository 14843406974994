.alert-container {
  z-index: 999;
  top: 3em;
  right: 2.5em;

  .custom-alert {
    font-weight: $font-weight-bold;
    font-size: $span-font-size;
    color: $light;
    line-height: 1.2em;
    min-width: 30em;
  }
}

.text-error {
  @extend .text-danger, .fw-medium, .position-absolute, .fs-7;
  left: 0rem;
  top: calc(100% + 0.1rem);
}
