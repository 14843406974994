.footer-container {
  width: 100%;
  background-color: var(--bs-bg-color-2);
}

.footer {
  span {
    font-size: $span-font-size;
  }
  @include min-break-point(mobile-phone) {
    justify-content: center;
  }
}
