.skill-container {
  button {
    min-width: 8.39em;
    border-radius: 0.3125em;
    height: 3.6em;
  }

  .scroll {
    max-height: 10em;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.4em;
    }

  }
}
