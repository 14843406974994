$icomoon-font-family: "intelliante" !default;
$icomoon-font-path: "../../icons/fonts" !default;

$intelant-tabler_writing: "\e903";
$intelant-file-text: "\e904";
$intelant-award: "\e905";
$intelant-tutorial: "\e902";
$intelant-codesandbox: "\e901";
$intelant-list: "\e900";


@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?8sosx');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?8sosx#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?8sosx') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?8sosx') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?8sosx##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="intelant-"], [class*=" intelant-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.intelant-tabler_writing {
  &:before {
    content: $intelant-tabler_writing; 
  }
}
.intelant-file-text {
  &:before {
    content:  "\e904"; 
  }
}
.intelant-award {
  &:before {
    content: $intelant-award; 
  }
}
.intelant-tutorial {
  &:before {
    content: $intelant-tutorial;     
    color: #778583;
  }
}
.intelant-codesandbox {
  &:before {
    content: $intelant-codesandbox; 
  }
}
.intelant-list {
  &:before {
    content: $intelant-list;     
    color: #fff;
  }
}

