.sign-in.sign-up {
  .srv-validation-message {
    color: $warning;
  }

  .verify {
    strong {
      color: $primary-color;
    }

    .para {
      font-size: $font-size-base;
      color: $primary-color;
      font-weight: $font-weight-normal;
    }

    .send-btn {
      background-color: $warning;
      font-size: 0.938em;
      transition: 0.2s ease;
      font-weight: $font-weight-bold;
      color: $white;
      width: 80%;
      line-height: 2.2;

      &:hover {
        background-color: $primary-color;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .resend {
      font-size: 1em;
      cursor: pointer;
      transition: 0.2s ease;
      color: $warning;

      &:hover {
        color: $primary-color;
      }
    }

    .form {
      max-width: 100%;
      margin: 1.563em auto 0;

      input {
        font-size: 1.25em;
        font-weight: $font-weight-bold;
        margin: 0 0.2em;
        text-align: center;
        border: 0.063em solid $border-color;
        outline: none;
        width: 2.5em;
        height: 2.5em;
        transition: all 0.2s ease-in-out;
        border-radius: 4px;
        background-color: transparent;
        color: $light;

        &:focus {
          background-color: var(--bs-gray-200);
          box-shadow: none;
          color: $text-color;
          border-color: $text-color;
        }
      }
    }
  }

  .modal-dialog {
    max-width: 45%;

    @include max-break-point(tablet) {
      max-width: 90%;
    }

    .modal-content {
      background-color: var(--bs-bg-color-1);
      border-radius: $border-radius;
      padding: 2em;

      .modal-header {
        border-bottom: none;

        .btn-close {
          position: absolute;
          background: url("../../images/times.svg");
          right: 5%;
          top: 5%;
          z-index: 1;

          &:focus {
            box-shadow: none;
          }
        }
      }

      .modal-body {
        h2 {
          font-size: 1.25em;
          font-weight: $font-weight-bold;
          color: $primary-color;
        }

        p {
          font-size: 1em;
          font-weight: $font-weight-bold;
        }
      }

      .modal-footer {
        border-top: none;
      }

      p {
        text-decoration: none;
        font-size: 0.938em;
        transition: 0.2s ease;
        font-weight: bolder;
        color: $warning;
        transition: 0.2s ease;
        cursor: pointer;

        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}

.login-form {
  .invalid,
  .valid {
    align-items: center;
  }
}

.form-control:disabled{
  background-color: transparent;
}