.logo-img {
  @include size(26.25em, 25em);
  top: -1.45rem;
  right: 0;
  background-image: url("/assets/images/Intelliante_Logo.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.candidate-detail-container {
  max-width: 42.688em;

  .applicant-detail {
    font-weight: $font-weight-semibold;
    line-height: 1.4em;
    font-size: $header-font-size;
    color: var(--bs-text);
    span {
      font-weight: inherit;
      color: $secondary-color;
    }
  }

  .summary-detail {
    font-size: $h3-font-size;
    font-weight: $font-weight-normal;
    line-height: 1.375em;
    padding-right: 5em;
    color: var(--bs-text);
    span {
      color: $secondary-color;
    }
  }

  h3 {
    span {
      font-weight: inherit;
    }
    .company-name {
      color: $secondary-color;
    }
  }
}

.sticky-nav {
  background-color: rgba(var(--bs-primary-rgb), 0.8);
  border: 0.125em solid $secondary-color;
  right: 0;
  top: 55%;
  z-index: 10;

  ul {
    list-style: none;
    padding-left: 0.7em;
    padding-right: 0.7em;

    a {
      border-radius: 50%;
    }

    .active {
      background-color: $text-color !important;
    }

    li {
      @include size(1.3em);
      background-color: transparent !important;
      border: 0.125em solid $text-color;
      cursor: pointer;
    }
  }
}

.section-title {
  color: var(--bs-text);
  span {
    color: $secondary-color;
  }
}

.title-alt {
  font-size: 1.75em;
  color: var(--bs-text);
}

.subtitle {
  font-size: $h5-font-size;
  font-weight: $font-weight-normal;
  color: var(--bs-text);
}

.steps-container {
  .step-1 {
    &::before {
      content: "";
      position: absolute;
      top: 36%;
      left: 0;
      width: 32%;
      border-top: 0.188em dashed $secondary-color;
      transform: translateZ(5.063em);
    }

    &::after {
      content: "";
      position: absolute;
      top: 36%;
      right: -25em;
      width: 35.5em;
      border-top: 0.188em dashed $secondary-color;
      transform: translateZ(-0.063em);
    }
  }

  .step-2 {
    &::after {
      content: "";
      position: absolute;
      top: 36%;
      right: -17em;
      width: 35.5em;
      border-top: 0.188em dashed $secondary-color;
      transform: translateZ(-0.063em);
    }
  }

  .step-3 {
    &::after {
      content: "";
      position: absolute;
      top: 36%;
      right: 0;
      width: 12em;
      border-top: 0.188em dashed $secondary-color;
      transform: translateZ(-0.063em);
    }
  }

  small {
    font-size: 2em;
    color: $secondary-color;
  }

  .steps {
    .stepsEL-wrapper {
      background-color: var(--bs-bg-color-2);
      z-index: 5;
    }
    .steps-name {
      background-color: $secondary-color;
      border-radius: 2.5em;
      color: $dark;
      font-size: $h2-font-size;
      font-weight: $font-weight-medium;
    }
  }
  .steps-description {
    line-height: 1.2em;
    color: var(--bs-text);
  }
}

.list-tooltip {
  border: 0.113em solid $text-color;
  border-radius: $border-radius;
  max-width: 18.75em;
  background-color: transparent;

  &:after {
    border-left: solid transparent 0.813em;
    border-right: solid transparent 0.813em;
    border-top: solid $text-color 1.25em;
    top: -1.25em;
    content: " ";
    left: 8em;
    position: absolute;
    transform: rotate(180deg);
    z-index: 2;
  }

  &:before {
    border-left: solid transparent 0.75em;
    border-right: solid transparent 0.75em;
    border-top: solid var(--bs-bg-color-2) 1.188em;
    top: -1.16em;
    content: " ";
    left: 8.05em;
    position: absolute;
    transform: rotate(180deg);
    z-index: 3;
  }

  ul {
    li {
      font-size: $font-size-base;
      color: $text-color;

      span {
        color: $secondary-color;
        font-weight: $font-weight-medium;
      }
    }
  }
}

// results
.result {
  h2 {
    font-weight: $font-weight-normal !important;
  }

  .icon-status-container {
    border-radius: 3.125em;
    border: 0.063 solid;
    position: relative;

    .result-icon {
      @include size(1.289em);
    }

    .arm-icon {
      path {
        fill: $text-color;
      }
    }

    .star-icon {
      path {
        stroke: $text-color;
        stroke-width: 0.075em;
      }
    }

    .users-icon {
      path {
        stroke: $text-color;
        stroke-width: 0.075em;
      }
    }

    .result-status {
      font-size: $font-size-lg;
      color: $text-color;
    }
  }

  .stand-out-color {
    border-color: $secondary-color;

    &::after {
      content: "";
      background-color: $secondary-color;
      opacity: 0.2;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 3.125em;
      z-index: -2;
    }
  }

  .watch-out-color {
    border-color: var(--bs-danger);

    &::after {
      content: "";
      background-color: var(--bs-danger);
      opacity: 0.2;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 3.125em;
      z-index: -2;
    }
  }

  .most-applicant-color {
    border-color: var(--bs-secondary);
    &::after {
      content: "";
      background-color: var(--bs-secondary);
      opacity: 0.2;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 3.125em;
      z-index: -2;
    }
  }
}

// test assign to applicant
.test-assigned-container {
  background-color: rgba(var(--bs-body-rgb-1), 100%);
  border-radius: $border-radius;
  .green-text {
    color: $secondary-color;
    font-size: inherit;
    font-weight: inherit;
  }
}

.test-card-container {
  margin: 0 auto;

  .test-cards {
    background-color: var(--bs-bg-color-2);
    border: 0.063em solid $border-color;
    border-radius: $border-radius;
    width: 17.75em;

    .report-icon {
      path {
        stroke: $text-color;
      }
    }

    .overcoming-adversity-icon {
      path {
        fill: $text-color;
        stroke: none;
      }
    }

    small {
      border: 0.14em solid var(--bs-secondary);
      position: relative;
      display: inline-block;
      &::after {
        content: "";
        background-color: var(--bs-secondary);
        opacity: 0.2;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 0.4em;
        z-index: 1;
      }

      // background-color: rgba(#ffc107, 0.1);
      border-radius: $border-radius;
      font-size: $mini-font-size;
      color: $text-color;
    }

    p {
      font-size: $title-font-size;
      line-height: 1.1;
      color: $text-color;
    }

    .jobskill {
      border-color: $secondary-color;
      &::after {
        content: "";
        background-color: $secondary-color;
        opacity: 0.2;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 0.4em;
        z-index: 1;
      }
    }
  }

  .tick-icon {
    @include size(1.563em);

    background-color: $secondary-color;
    border-radius: 50%;
    right: 1em;
    top: 0.8em;

    div {
      @include size(1.25em);
      background-color: $secondary-color;
      border-radius: 50%;
    }
  }

  .completed-tests {
    background-color: rgba(var(--bs-primary-rgb), 0.1);
    border: 0.125em solid $secondary-color;
  }
}

//performance-layout
.performance-layout {
  border: 0.063em solid $secondary-color;
  border-radius: $border-radius;
  width: 60%;
  z-index: 5;

  .listBulletPoint {
    background-color: $secondary-color;
  }

  .title-icon {
    background: var(--bs-bg-color-2);
    left: 3em;
    top: -1.2em;

    .title {
      font-size: $secondary-title-font-size;
      font-weight: $font-weight-bolder;
      color: $text-color;
    }

    .icon {
      @include size(2em);
    }
  }

  .arm-icon {
    path {
      fill: $text-color;
    }
  }

  .star-icon {
    path {
      stroke: $text-color;
      stroke-width: 0.075em;
    }
  }

  .users-icon {
    path {
      stroke: $text-color;
      stroke-width: 0.075em;
    }
  }
}

.performance-layout-watchout {
  border: 0.063em solid var(--bs-danger);
  .listBulletPointwatchout {
    background-color: var(--bs-danger);
  }
}

.performance-list {
  ul {
    height: 2.625em;
    list-style: none;

    .bullet-point {
      @include size(0.75em);

      border-radius: 50%;
      flex-shrink: 0;
      margin-top: 0.4em;
    }

    .list {
      color: $text-color;
      font-size: $li-font-size;
      text-align: left;
      vertical-align: baseline;
    }
  }

  .performace-list-container {
    height: 100%;

    .list-container {
      text-align: left;
    }

    .title {
      font-size: $h4-font-size;
      font-weight: $font-weight-mid-bold;
      color: $text-color;
    }

    .description {
      color: $text-color;
      font-size: $li-font-size;
    }
  }
}

.no-list-container {
  .no-list-icon {
    path {
      fill: $text-color;
    }
  }

  .no-list-description {
    color: $text-color;
    font-size: $h5-font-size;
    line-height: 1.5em;
    width: 60%;
  }
}

// for line-breaker
.line-breaker {
  background-image: linear-gradient(
    to right,
    rgba($light-dark, 0.2),
    $secondary-color,
    rgba($light-dark, 0.2)
  );
  height: 0.063em;
}

// for key-layout
.key-layout {
  background-color: rgba(var(--bs-body-rgb-1), 100%);
  border: 0.125em solid $border-color;
  border-radius: $border-radius;
  width: 75em;

  h1 {
    font-size: 3.125em;
    font-weight: $font-weight-bolder;
    margin: 0;
    text-align: center;
    color: $text-color;
  }

  p {
    font-size: $h5-font-size;
    font-weight: $font-weight-normal;
    text-align: center;
    color: $text-color;
  }
}

.raw-kpm {
  border-right: 0.125em solid $border-color;
}

.big-arrow {
  margin-bottom: -1.65em;
  margin-top: -1.65em;
  path {
    stroke: $border-color;
  }
}

//for Overall competitiveness
.overall-competitiveness {
  margin-top: 4rem;
  .normative-container {
    @include size(16.9vh, 66.1vw);

    background-color: red;
    border-radius: $border-radius;
    background-image: linear-gradient(to right, #ff4e45, #1db27e);
  }

  .normative-bar-container {
    top: -0.2em;
    bottom: -0.2em;
    background-color: var(--bs-bg-color-2);
    &::before {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.544em 0.313em 0 0.313em;
      border-color: var(--bs-secondary) transparent transparent transparent;
      top: -0.8em;
      left: 0.1em;
      position: absolute;
    }

    &::after {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0.313em 0.544em 0.313em;
      border-color: transparent transparent var(--bs-secondary) transparent;
      bottom: -0.8em;
      right: 0em;
      position: absolute;
    }

    .normative-bar {
      @include size(100%, 0.25em);

      background-color: #3fd4a0;
      border-radius: $border-radius;
    }
  }
}
