.overlay {
  position: fixed;
  z-index: 98;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: 0;
}

.modal-backdrop {
  z-index: -1;
}

.modal {
  // background-color: $primary-overlay;
  backdrop-filter: blur(0.5em);
  &-title {
    color: var(--bs-gray);
  }

  .modal-content {
    // background-color: var(--bs-bg-color-1);
    border: none;
  }

  .modal-header {
    border-bottom: 2px solid var(--bs-gray-200);
  }

  .desc {
    h4 {
      font-size: 1.5em;
      font-weight: $font-weight-semibold;
    }
    .code {
      font-weight: $font-weight-normal;
    }

    .category {
      font-size: 0.875em;
      color: $text-color;
    }

    .summary {
      font-size: 0.875em;
      line-height: 1.7em;
      max-height: 22.875em;
      overflow: auto;
    }

    .read-or-hide {
      font-weight: $font-weight-bold;
    }
  }

  h3 {
    font-size: 1.125em;
    background-color: var(--bs-bg-color-1);
    border-radius: $border-radius-sm;
  }

  .modal-footer {
    border: none;
    background-color: var(--bs-gray-50);
    // filter: brightness(.8);

    .close-btn {
      background-color: transparent;
      color: $text-color;
      border: 2px solid $text-color;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0.5em;
    border-radius: $border-radius-sm;
  }
}

.delete-holder {
  z-index: $zindex-tooltip;
  // border: 1px solid var(--bs-gray-200);
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
}

.del-modal {
  .modal-dialog {
    // @include size(auto, 20.9375em);
  }

  .modal-content {
    @extend .position-relative;

    border-color: $border-color;

    &::before,
    &::after {
      @extend .position-absolute;
      @include size(0, 0);

      content: "";
      border: 0.625em solid transparent;
      border-top: 0.625em solid var(--bs-bg-color-1);
      bottom: -1.25em;
      left: 78%;
    }

    &::before {
      border-width: 0.75em;
      border-top-width: 0.75em;
      border-top-color: rgba(var(--bs-btn-bg), 100%);
      bottom: -1.5em;
      margin-left: -0.125em;
    }

    p {
      @extend .pt-2;

      font-size: 1.125em;
      color: var(--bs-gray-900);
    }
  }
}

.delete-holder:not(:empty) {
  + div {
    .del-icon path,
    .del-icon rect {
      stroke: rgba(var(--bs-danger-rgb), 100%);
    }
  }
}

.delete-holder {
  + div {
    .del-icon:hover path,
    .del-icon:hover rect {
      stroke: rgba(var(--bs-danger-rgb), 100%);
    }
  }
}

.delete-holder {
  + div {
    .edit-icon:hover path,
    .edit-icon:hover rect {
      stroke: rgba(var(--bs-success-rgb), 100%);
    }
  }
}
