.btn {
  @extend .cursor-pointer;
  &-primary {
    background: $primary-color;
    border-color: $primary-color;
    min-width: 11.875rem;

    &:hover,
    &:focus,
    &:active {
      background: $primary-color !important;
      border-color: $primary-color !important;
    }
  }

  &-secondary {
    background: $secondary-color;
    border-color: $secondary-color;

    min-width: 11.875rem;
    &:hover,
    &:focus,
    &:active {
      background: $secondary-color;
      border: 0.0625rem solid $secondary-color;

      filter: brightness(0.95);
    }
  }

  &-outline-secondary {
    background: transparent;
    border: 1px solid $secondary-color;
    color: $secondary-color;
    min-width: 11.875rem;

    &:hover {
      background: $secondary-color;
      border: 1px solid $secondary-color;
      filter: brightness(0.95);
    }
    &:focus {
      background: $secondary-color;
      border-color: 1px solid $secondary-color;
    }
  }
  &.back,
  &.next {
    color: var(--bs-gray-500);

    &:hover,
    &:focus {
      color: var(--bs-gray-700);
      box-shadow: none !important;
    }
  }
  svg {
    fill: var(--bs-primary);
    width: 2em;
    height: 2em;
    border: 0.1rem solid var(--bs-primary);
    border-radius: 2rem;
    padding: 0.3rem;
  }
  svg path {
    stroke: var(--bs-primary);
  }
}
