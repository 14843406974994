.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .select-floating ~ label {
  padding: 0;
  background-color: $bg-secondary-body;
  opacity: 1;
  height: 1.5rem;
  width: auto;
  left: 0.875rem;
  top: -0.1875rem;
}
.form-floating > label {
  padding: 0.7rem 0.625rem;
  font-size: 0.875rem;
}

.select-floating {
  position: relative;
}

.select-floating > .dropdown:focus ~ label,
.select-floating > .dropdown ~ label {
  position: absolute;
  padding: 0 0.25rem;
  background-color: var(--bs-bg-color-2);
  opacity: 1;
  height: 1.5rem;
  width: auto;
  left: 0.3rem;
  top: -0.1875rem;
  display: inline-block;
  font-size: 0.875rem;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.css-1vlsb4t-placeholder {
  display: none;
}
.select-floating > .dropdown.no-placeholder ~ label {
  padding: 0.7rem 0.625rem;
  font-size: 0.875rem;
  left: 0.2875rem;
  top: 0.1rem;
  transform: none;
}
