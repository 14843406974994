.welcomepage-container {
    background-color: var(--bs-bg-color-2) !important;
    height: 100vh;
   
    color: var(--bs-text);

  .welcomepage-content-wrapper {
        background-color: rgba(var(--bs-body-rgb-1), 100%) !important;
        border-radius: $border-radius;

        .welcome-text {
            font-size: $header-font-size;
            font-weight: $font-weight-medium;
            color: inherit;
        }

        p {
            font-size: $h3-font-size;
        }

        .get-started-btn {
            padding: 0.875em 2.5em;
            width: 18.313em;
            border-radius: $border-radius-sm;
            outline: none;
            background-color: var(--bs-secondary);
            font-size: $h4-font-size;
            font-weight: $font-weight-medium;
            border: none;
        }
    }  

    .steps-wrapper {
        width: 18.75em;
        padding: 1.25em;
        border: 0.063em solid $border-color;
        border-right-width: 0.25em;
        border-radius: $border-radius;

        .step-num {
            color: $dark;
            background-color: var(--bs-secondary);
            top: -0.8em;
            border-radius: 0.625em;
        }

        .title {
            font-size: $title-font-size;
        }

        .time-icon {
            path {
                stroke: var(--bs-secondary);
            }
        }

        .time-description {
            font-size: $mini-font-size;
            .time {
                font-size: $font-size-base;
                color: var(--bs-secondary);
            }
        }
    }
}
