body,
.bg-body {
  background-color: var(--bs-bg-color-2) !important;
}
.wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main-content {
  flex: 1;
  position: relative;
  margin-top: 1rem;
}

.max-w-1970 {
  max-width: 93.75rem;
}
.bg-section {
  background-color: var(--bs-bg-color-1) !important;
  border-radius: 0.5rem;

  // We are overwriting bootstrap fixes
  &-100 {
    background: rgba(var(--bs-body-rgb-2), 0.3) !important;

    &:hover {
      background: rgba(var(--bs-body-rgb-2), 0.4) !important;
    }
  }
}

section {
  @extend .rounded;
}

figure {
  margin: 0;
}

.h-40 {
  height: 40px;
}

.fw-semibold {
  font-weight: $font-weight-semibold;
}

.fw-medium {
  font-weight: 500;
}

.fw-medium {
  font-weight: $font-weight-medium;
}

.fs-7 {
  font-size: 0.75rem !important;
}
.cursor-pointer {
  cursor: pointer;
}
.box {
  padding: 20px;
  border: 0.0625em solid $border-color;
  background-color: $bg-body !important;

  &:hover {
    border-color: $primary-color;
  }

  @extend .rounded-2, .bg-body, .border-1;

  &-image-sm {
    @extend .overflow-hidden;
    border: 0.0625em solid $primary-color;
    min-width: 70px;
    height: 65px;
    width: 70px;
  }

  &-sm {
    padding: 10px;
  }
}

.border-none {
  border: none !important;
}

.text-secondary {
  color: $secondary-color !important;
}

.text-muted {
  color: var(--bs-gray-50) !important;
}

.border-primary {
  border-color: $primary-color !important;
}

.border-secondary {
  border-color: $secondary-color !important;
}

.border-success {
  border-color: $primary-color !important;
}

.border-info {
  border-color: var(--bs-info) !important;
}

.border-warning {
  border-color: var(--bs-warning) !important;
}

.text-white {
  color: var(--bs-white) !important;
}

.text-warning {
  color: var(--bs-warning) !important;
}

.border-danger {
  border-color: var(--bs-danger) !important;
}

.text-danger {
  color: var(--bs-danger) !important;
}

.border-light {
  border-color: var(--bs-light) !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-light {
  color: var(--bs-light) !important;
}

.border-dark {
  border-color: var(--bs-dark) !important;
}

.text-dark {
  color: var(--bs-dark) !important;
}

.text-gray {
  color: var(--bs-gray-600);
}

.border-primary {
  border-color: rgba(var(--text-secondary-rgb), 100%) !important;
}

.bg-dark {
  background: rgba(var(--bs-body-rgb-1), 100%) !important;
}

.color-icon path,
.color-icon circle {
  transition: all 400ms ease;
}

.color-icon.fill path,
.color-icon.fill circle {
  stroke: none;
  fill: var(--bs-gray-400);
  transition: all 400ms ease;
}

.nav-link .color-icon path,
.nav-link .color-icon circle {
  stroke: rgba(var(--bs-icon-rgb), 70%);
  transition: all 400ms ease;
}

.nav-link .color-icon.fill path,
.nav-link .color-icon.fill circle {
  stroke: none;
  fill: rgba(var(--bs-icon-rgb), 70%);
  transition: all 400ms ease;
}

.active .color-icon path,
.active .color-icon circle,
.nav-link a:hover .color-icon path,
.nav-link a:hover .color-icon circle {
  stroke: $primary-color;
}

.active .color-icon.fill path,
.active .color-icon.fill circle {
  stroke: none;
  fill: var(--bs-gray-400);
}

.white.color-icon path,
.white.color-icon circle {
  stroke: rgba(255, 255, 255, 1);
}

.color-progress.color-icon path,
.color-progress.color-change:not(.color-toggler) path,
.color-progress.color-icon circle,
.color-progress.color-change:not(.color-toggler) circle {
  stroke: $secondary-color;
}

.color-success.color-icon path,
.color-success.color-change:not(.color-toggler) path,
.color-success.color-icon circle,
.color-success.color-change:not(.color-toggler) circle {
  stroke: var(--bs-bg-color-1);
}

.color-lock.color-icon path,
.color-lock.color-change:not(.color-toggler) path,
.color-lock.color-icon circle,
.color-lock.color-change:not(.color-toggler) circle {
  stroke: $secondary-color;
}

.fill.color-change path,
.fill.color-toggler.color-icon path,
.fill.color-icon path,
.fill.color-change:not(.color-toggler) path {
  stroke: none;
  fill: var(--bs-gray-400);
}

.btn-primary .color-change path,
.btn-primary .color-toggler.color-icon path,
.btn-primary .color-icon path,
.btn-primary .color-change:not(.color-toggler) path {
  stroke: rgba(var(--bs-btn-text), 100%);
}

.btn-primary .fill.color-change path,
.btn-primary .fill.color-toggler.color-icon path,
.btn-primary .fill.color-icon path,
.btn-primary .fill.color-change:not(.color-toggler) path {
  stroke: none;
  fill: rgba(var(--bs-btn-text), 100%);
}

.primary.color-change path,
.primary.color-toggler.color-icon path,
.primary.color-icon path,
.primary.color-change:not(.color-toggler) path,
.primary.color-icon rect {
  stroke: $primary-color;
}

.danger.color-change path,
.danger.color-toggler.color-icon path,
.danger.color-icon path,
.danger.color-change:not(.color-toggler) path,
.danger.color-icon rect {
  stroke: var(--bs-danger);
}

.disable.color-change path,
.disable.color-toggler.color-icon path,
.disable.color-icon path,
.disable.color-change:not(.color-toggler) path,
.disable.color-icon rect {
  stroke: rgba(var(--bs-disable), 50%);
}

.disable.fill.color-change path,
.disable.fill.color-toggler.color-icon path,
.disable.fill.color-icon path,
.disable.fill.color-change:not(.color-toggler) path,
.disable.fill.color-icon rect {
  stroke: none;
  fill: rgba(var(--bs-disable), 50%);
}

.danger.fill.color-change path,
.danger.fill.color-toggler.color-icon path,
.danger.fill.color-icon path,
.danger.fill.color-change:not(.color-toggler) path,
.danger.fill.color-icon rect {
  stroke: none;
  fill: var(--bs-danger);
}

.primary.fill.color-change path,
.primary.fill.color-toggler.color-icon path,
.primary.fill.color-icon path,
.primary.fill.color-change:not(.color-toggler) path {
  stroke: none;
  fill: $primary-color;
}

.bg-danger {
  color: var(--bs-white) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--text-primary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-progress {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-btn-bg), var(--bs-bg-opacity)) !important;
}

.btn-outline-heading {
  border-color: rgba(var(--bs-headings), 100%) !important;
  color: rgba(var(--bs-headings), 100%) !important;
  // background-color: var(--bs-bg-color-1);
}

.nav-link {
  .active {
    .text-gray {
      color: $primary-color !important;
    }
  }
}

.mtn-bal {
  margin-top: -5rem;
  padding-bottom: 5rem;
}

p,
div {
  margin: 0;
}

select {
  &:focus {
    outline: none !important;
    border-color: rgba(var(--text-secondary-rgb), 50%) !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
  color: var(--bs-gray);

  &:hover,
  &:focus {
    color: $primary-color;
  }
}

.ffYIwj > span {
  opacity: 0.8 !important;
}

.invalid {
  span {
    color: rgba(var(--bs-danger-rgb), 100%);
    font-size: $span-font-size;
  }

  path {
    fill: rgba(var(--bs-danger-rgb), 100%) !important;
  }
}

.valid {
  span {
    font-size: 0.8em;
    color: rgba(var(--bs-success-rgb), 100%);
  }

  path {
    fill: rgba(var(--bs-success-rgb), 100%) !important;
  }
}

.theme-switch {
  margin-bottom: 20px;
}

.fa-search-icon + label {
  margin-left: 25px;
}

button {
  &.interactive-click {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
  }
}

.inherit-reset {
  font: inherit;
  color: inherit;

  &:hover {
    color: inherit;
  }
}

.btn-grid {
  --bs-gutter-x: 0.625em;
  --bs-gutter-y: 0.625em;

  & > * {
    --bs-gutter-x: 0.625em;
  }
}

.text-disable {
  color: rgba(var(--bs-disable), 50%) !important;
}

iframe {
  height: 100vh;
  width: 100%;
}

.theme-customizer {
  z-index: 999 !important;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.z-index-1 {
  z-index: 1;
}

.lg-list {
  .custom-control {
    @extend .ps-5;
  }
}

.custom-control {
  @extend .position-relative, .flex-column;

  padding-left: 2.5rem !important;
}

.label-container {
  @extend .d-block, .user-select-none;

  font-size: 1rem;
  cursor: pointer;

  &:focus {
    color: var(--bs-gray-900);
  }
}

.checkmark::after {
  @extend .position-absolute, .d-none;

  content: "";
}

.checkmark {
  @extend .position-absolute, .top-0, .start-0, .rounded-pill;
  padding-right: 5px;
  height: 1.5625rem;
  width: 1.5625rem;
  border: 0.125rem solid var(--bs-gray-600);
  background-color: transparent;

  &::after {
    @extend .rounded-pill;

    top: 0.1875rem;
    left: 0.1875rem;
    width: 0.9375rem;
    height: 0.9375rem;
    background: $primary-color;
  }
}

* {
  ::-webkit-scrollbar {
    border-radius: $border-radius-sm;
    width: 0.76em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.675em rgba(0, 0, 0, 0.3);
    background-color: var(--bs-gray-200);
  }

  ::-webkit-scrollbar-thumb {
    background-color: $border-color;
    border-radius: $border-radius-sm;
  }
}

.btn:disabled {
  cursor: not-allowed;
  pointer-events: initial;
}

.action-icon img {
  @include size(1.25em);

  cursor: pointer;
}

.px-2b {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.pe-2e {
  padding-right: 2.5rem;
}

.dot-flashing {
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  background-color: $white;
  border-radius: 0.313em;
  color: $white;
  height: 0.625em;
  position: relative;
  width: 0.625em;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
    background-color: $white;
    border-radius: 0.313em;
    color: $white;
    height: 0.625em;
    left: -0.938em;
    width: 0.625em;
  }

  &::after {
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
    background-color: $white;
    border-radius: 0.313em;
    color: $white;
    height: 0.625em;
    left: 0.938em;
    width: 0.625em;
  }
}

@keyframes dotFlashing {
  0% {
    background-color: $white;
  }

  50%,
  100% {
    background-color: $primary-color;
  }
}

svg {
  path {
    stroke: var(--bs-gray-700);
  }
}

.min-h-full {
  min-height: 100% !important;
}

.status {
  background-color: $primary-color;
}

.rounded-circle {
  width: 18px;
  height: 18px;

  @extend .position-relative, .bg-primary;

  svg {
    @extend .position-absolute;

    height: 10px;
    width: 5px;
    left: 5px;
    top: 2px;

    path {
      fill: $body-bg;
      stroke: $body-bg;
    }
  }
}

.error-msg {
  color: var(--bs-danger);
  font-size: $li-font-size;
}

.bg-secondary-dark {
  background-color: rgba(var(--bs-body-rgb-1), 60%);
}

.max-h-400 {
  max-height: 290px;
}

.overflow-x-auto {
  overflow-x: auto;
}

.list-block {
  @extend .position-relative,
    .bg-dark,
    .d-flex,
    .align-items-start,
    .flex-wrap,
    .mb-5;
  border-radius: 0.5rem;
  border: 1px solid $border-radius;
}
.list-items {
  padding: 1.5rem 1.875rem;
  @extend .w-100;
}

.view-block {
  @extend .btn,
    .px-3,
    .py-1,
    .btn-outline-heading,
    .position-absolute,
    .translate-middle-x,
    .bg-dark;
  bottom: -20px;
  left: 50%;

  svg {
    border: none;
  }
  .arrow {
    height: 22px;
    width: 20px;
    padding: 0;
  }
}
