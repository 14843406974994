.add-another {
  .add {
    border-radius: $border-radius-sm;
    color: $light;

    &:focus {
      box-shadow: none;
    }
  }

  .list-title {
    font-size: $h5-font-size;
    font-weight: $font-weight-bold;
    color: $text-color;
  }

  .responsive-table {
    .table-header {
      background-color: var(--bs-gray-200);
      border-radius: $border-radius-sm;
      line-height: 3em;
      margin-bottom: 0.688em;
    }

    .header-text {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      color: $text-color;
    }

    li {
      border-radius: $border-radius-sm;
      padding: 0.563em 2.188em;
      margin-bottom: 0.5em;
    }

    .table-row {
      min-height: 4.688em;
      border-radius: $border-radius-sm;
    }

    .head {
     
      font-weight: $font-weight-bold;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .sub-head {
     
      font-weight: $font-weight-normal;
      color: $text-color;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .edu-list {
    max-height: 18.5em;

    .edit {
      border: 0.0625em solid $primary-color;
    }

    .delete-holder {
      top: 3em;
      right: -1.5em;
    }

    .modal-content {
      background: $bg-body;
    }
    .modal-content:after,
    .modal-content:before {
      bottom: 100%;
      left: 90%;
      border: 0.1em solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    .modal-content:after {
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: $bg-secondary-body;
      border-width: 0.8em;
      margin-left: -0.8em;
    }
    .modal-content:before {
      border-color: rgba(194, 225, 245, 0);
      border-width: 1em;
      margin-left: -1em;
    }
  }

  .__react_component_tooltip.place-top {
    background-color:$bg-body;
    border-color: var(--bs-gray-800);

    &::after {
      border-top-color: var(--bs-gray-200);
    }
  }
}
.another-btn {
  color: $text-color;
  transition: 0.2s ease;

  &:hover {
    color: $text-color;
    border: 0.06em solid $warning;
  }
}
