.candidate-identification {
  .close {
    right: -5%;
    top: -6%;
    width: 1.535em;
    height: 1.54em;
    padding-left: 0.12em;
  }

  .camera-shot {
    width: 6.2em;
    height: 7.7em;
    border-radius: 0.125em;
  }
}
