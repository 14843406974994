.layout {
  .form {
    width: 100%;

    @include min-break-point(tablet) {
      width: 90%;
    }

    @include min-break-point(tablet-landscape) {
      width: 80%;
    }

    @include min-break-point(large-desktop) {
      width: 90%;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
