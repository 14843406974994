%spinner {
  animation: spinner linear infinite forwards;
  animation-duration: 1.4s;
}

.custom-spinner {
  svg {
    @extend %spinner;
  }
}

svg.custom-spinner {
  @extend %spinner;
}

.individual-game {
  display: grid;
  grid-template-columns: repeat(16, 1fr);

  .collapse-game {
    &:not(.collapsed)::after {
      transform: rotate(0deg);
      color: $light;
    }

    &:after {
      transform: rotate(-90deg);
      filter: invert(79%) sepia(98%) saturate(389%) hue-rotate(338deg)
        brightness(150%) contrast(90%);
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .menu {
    @include size(2.5em);

    border-radius: 0.18em;
  }

  .game-container {
    grid-column: 1/17;

    @include min-break-point(mobile-phone) {
      grid-column: 2/17;
    }

    @include min-break-point(larger-desktop) {
      max-width: 100%;
    }
  }

  .menu-container {
    grid-column: 1/1;
  }

  .dotted-line-container {
    grid-column: 10/14;
  }

  .dotted-line {
    border-left: 1px dashed rgba(var(--bs-headings), 40%);
    margin-left: 1.25em;
    &.desktop {
      height: 5em;
      border-left: 2px dashed rgba(var(--bs-headings), 40%);
    }
  }

  @keyframes spinner {
    0% {
      @include transform(rotate(0deg));
    }
    100% {
      @include transform(rotate(360deg));
    }
  }

  .game-content {
    gap: 1em 0;
  }

  .lock {
    @include user-select(none);

    top: 0;
    left: 0;
    background: rgba(62, 72, 70, 0.67);
    cursor: default;
    z-index: 1;

    //other browser
    @supports (backdrop-filter: blur(0.125em)) {
      backdrop-filter: blur(0.125em);
    }

    //firefox
    @supports not (backdrop-filter: blur(0.125em)) {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        filter: blur(1.25em);
        z-index: -1;
      }
    }
  }
}

.lock-holder {
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  background-color: var(--bs-bg-color-1);;
  top: 0.625em;
  right: 1.25em;
}
