.session-modal {
  @include size(27.8125rem, 42.6875rem);

  .session-timeout-icon {
    width: 75%;
    height: 9.4375rem;

    .sleeping-icon {
      @extend %absoluteCenter;

      z-index: 20;

      path {
        stroke: var(--bs-bg-color-1);
      }
    }

    .icon-circle {
      @include size(9.4375rem, 9.4375rem);

      background-color: var(--bs-gray-400) !important;

      &::before,
      &::after {
        content: "";

        @include size(1rem, 1rem);
        background-color: var(--bs-gray-400);
        position: absolute;
        border-radius: 50%;
      }

      &::before {
        top: 60%;
        left: -15%;
      }

      &::after {
        top: 50%;
        right: -15%;
      }
    }

    .icon-dialog-box {
      @include size(75%, 65%);

      @extend %absoluteCenter;

      &-1 {
        top: 54%;
        left: 54%;
        z-index: 10;

        &::after {
          content: "";

          @include size(0.625rem, 0.625rem);

          background-color: var(--bs-gray-400);
          position: absolute;
          border-radius: 50%;

          top: -10%;
          left: -20%;
        }
      }

      background-color: var(--bs-gray-400);
      border: 0.0625rem solid var(--bs-white);
      border-radius: 0.5rem;

      .menu-bar {
        background-color: var(--bs-gray-50);

        border-bottom: 0.0625rem solid var(--bs-white);
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
        height: 15%;

        .menu-icons {
          gap: 0.25rem;

          .menu-icon {
            @include size(0.45rem, 0.45rem);

            background-color: var(--bs-gray) !important;
          }
        }
      }
    }
  }

  border-radius: 1rem;
  gap: 2rem;

  h2 {
    color: var(--bs-text);
    line-height: 1.8125rem;
  }

  p {
    color: var(--bs-text);
    font-size: $h4-font-size;
    font-weight: $font-weight-bold;
    line-height: 1.5rem;
  }

  button {
    @include size(2.875rem, 10.9375rem);

    border-radius: 0.3125rem;
    font-size: 1rem;
    font-weight: $font-weight-bold;
    line-height: 1.875rem;

    &:hover {
      background-color: $secondary-color;
    }
  }
}
