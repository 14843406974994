@import "~/node_modules/bootstrap/scss/bootstrap";

:root {
  --bs-primary: rgb(var(--bs-primary-rgb));
  --bs-secondary: rgb(var(--bs-secondary-rgb));
  --bs-text: rgb(var(--bs-text-primary-rgb));

  --bs-border-color: rgb(var(--border-color-rgb));

  --bs-btn-color: var(--bs-primary);
  --opacity-05: 0.05;
  --opacity-10: 0.1;
  --opacity-20: 0.2;
  --opacity-30: 0.3;
  --opacity-40: 0.4;
  --opacity-50: 0.5;
  --opacity-60: 0.6;
  --opacity-70: 0.7;
  --opacity-80: 0.8;
  --opacity-90: 0.9;
  --opacity-1: 1;

  --bs-gray-scale: var(--bs-text-primary-rgb);

  --bs-gray: rgb(var(--bs-gray-scale));
  --bs-gray-50: rgb(var(--bs-gray-scale), var(--opacity-05));

  --bs-gray-100: rgb(var(--bs-gray-scale), var(--opacity-10));
  --bs-gray-200: rgb(var(--bs-gray-scale), var(--opacity-20));
  --bs-gray-300: rgb(var(--bs-gray-scale), var(--opacity-30));
  --bs-gray-400: rgb(var(--bs-gray-scale), var(--opacity-40));
  --bs-gray-500: rgb(var(--bs-gray-scale), var(--opacity-50));
  --bs-gray-600: rgb(var(--bs-gray-scale), var(--opacity-60));
  --bs-gray-700: rgb(var(--bs-gray-scale), var(--opacity-70));
  --bs-gray-800: rgb(var(--bs-gray-scale), var(--opacity-80));
  --bs-gray-900: rgb(var(--bs-gray-scale), var(--opacity-90));

  --bs-bg-color-1: rgb(var(--bs-body-rgb-1));
  --bs-bg-color-2: rgb(var(--bs-body-rgb-2));
  --bs-light: rgb(var(--bs-heading-color));

  --bs-body-color: var(--bs-gray-600);

  --bs-white: var(--bs-text);

  --border-gray-400: rgb(var(--border-color-rgb));
}

$white: #fff;
