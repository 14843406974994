.dashboard-layout {
  // .information {
  //   width: 100%;
  //   left: 0%;
  //   max-width: none;
  //   margin-top: 6em;
  // }

  .notification {
    border-left: 1px dashed $body-color;
    cursor: pointer;

    span {
      @include size(1.6em);

      font-weight: $font-weight-bolder;
      font-size: $span-font-size;
      border-color: none;
    }
  }
}
