.bg-figure {
  fill: $bg-secondary-body;
  stroke: $border-color;
}
.bg-figure-primary {
  fill: $primary-color;
  stroke: $border-color;
}

.bg-figure-block {
  fill: $bg-body;
  stroke: $border-color;
}
