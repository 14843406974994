.header {
  & .title {
    @extend .fs-5;
    font-weight: $font-weight-semibold;
    line-height: 1.875em;
  }
}

.detail-card {
  color: var(--bs-text);
  transition: all 0.3s ease-in-out;
  transform-style: preserve-3d;
  background-color: var(--bs-bg-color-1);
  max-height: 9.375em;
  &:hover {
    border: 0.0625em solid $primary-color;
  }
}

.job-overview, .background-overview {
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -1em;
    width: 1.5em;
    border-top: 0.188em dashed var(--bs-gray-600);
    transform: translateZ(-0.063em);
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -2em;
    width: 1.875em;
    border-top: 0.188em dashed var(--bs-gray-600);
    transform: translateZ(-0.063em);
  }

  .job-overview-link, .background-overview-link {
    display: none;
  }

  &:hover {
    .job-overview-link, .background-overview-link {
      background: $bg-secondary-body;
      display: block;
      position:absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      color: $white;
    }
  }
  .text-details {
    transition: all 0.5s ease-in-out;
    font-size: .875rem;
    color: var(--bs-secondary);
    position: relative;
    right: -0.625rem;

    svg {
      transform: rotate(90deg);
    }
    svg path {
      stroke: var(--bs-secondary);
    }
  }
}

.background::after,
.tests-card::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -1em;
  width: 1.875em;
  border-top: 0.188em dashed var(--bs-gray-600);
  transform: translateZ(-0.063em);
}

.title {
  font-size: $h5-font-size;
}

.details {
  font-weight: $font-weight-normal;
  font-size: $span-font-size;
}

.card-brief {
  width: 18em;
}

.card-status {
  width: 7.9em;
}

.job-status {
  font-weight: $font-weight-normal;
  font-size: $span-font-size;
}

.tests-container {
  padding-bottom: 1.8rem;
}

.tests-list-container {
  cursor: pointer;
  margin: 0 0.75em;
  margin-bottom: 2rem;
  border-radius: 0.5em;
  padding: 3rem 0;
  background-color: $bg-secondary-body;
  border: 0.063em solid $border-color;

  &:after {
    border-left: solid transparent 0.813em;
    border-right: solid transparent 0.813em;
    border-top: solid $bg-secondary-body 1.25em;
    top: -1.25em;
    content: " ";
    right: 18rem;
    position: absolute;
    transform: rotate(180deg);
    z-index: 2;
  }

  &:before {
    border-left: solid transparent 0.75em;
    border-right: solid transparent 0.75em;
    border-top: solid $bg-secondary-body 1.188em;
    top: -1.156em;
    content: " ";
    right: 18.1rem;
    position: absolute;
    transform: rotate(180deg);
    z-index: 3;
  }

  .tests-list-card {
    @extend .cursor-pointer;
    padding: 2em 0;
    background: var(--bs-bg-color-1);
    border-radius: 0.375em;
    border: 0.063em solid var(--bs-gray-200);

    &:hover {
      border-color: $primary-color;
      background-color: var(--bs-bg-color-2);
    }

    .test-name {
      font-size: $h5-font-size;
      font-weight: $font-weight-bold;
    }
  }
}

.job-title-header {
  border-left: .0625rem solid $border-color;
  
  p {
    font-size: $span-font-size;
  }
}

// for training course
.training-course-wrapper {
  .tab {
    font-size: $span-font-size;

    & button {
      color: var(--bs-gray-800);
      padding: 0.3rem 0;
      width: 10em;
      border: 0.063em solid $border-color;
      background-color: transparent;
    }

    & .active-tab {
      background-color: var(--bs-primary);
      color: var(--bs-gray);
    }
  }
}

.course-card-wrapper {
  .course-card-content {
    border-color: $border-color;
    border-radius: $border-radius;

    &:hover {
      border: 0.0625em solid $primary-color;
    }
    .course-card-img {
      max-width: 4.375em;
      height: 4.063em;
      border: 0.063em solid $primary-color;
      border-radius: 0.25em;
      flex-shrink: 0;

      img {
        object-fit: cover;
      }
    }

    .course-card-detail {
      h5 {
        margin-bottom: 0.25em;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
      p {
        font-size: $span-font-size;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}

/* the slides button*/
.slick-list {
  margin: 0 -0.625em;
}
.slick-slide > div {
  padding: 0 0.625em;
}

.slick-prev {
  left: -16px;
  z-index: 5;
  background-color: $primary-color;
}
.slick-next {
  right: -16px;
  z-index: 5;
  background-color: $primary-color;
}

.slick-prev:before,
.slick-next:before {
  @extend .d-none;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background-color: $primary-color;
}

.no-course-container {
  p {
    color: $text-color;
  }
}

.no-course-icon {
  path {
    fill: $text-color;
  }
}
