.step-header {
  border-bottom: 0.0625em solid var(--bs-gray-400);

  width: 100%;

  @include min-break-point(tablet) {
    width: 90%;
  }

  @include min-break-point(tablet-landscape) {
    width: 80%;
  }

  @include min-break-point(large-desktop) {
    width: 90%;
  }

  .secondary-text {
    color: $light;
  }
}
