.test-card {
  outline: 0.125em solid transparent;
  transition: outline 300ms ease;
  &:not(.locked):hover {
    cursor: pointer;
    outline-color: $primary-color;
  }

  &.locked {
    * {
      pointer-events: none;
    }
  }
  header {
    p {
      min-height: 7.5em;
      border-radius: $border-radius $border-radius 0 0;
    }
    svg.position-absolute {
      @extend %absoluteCenter;
    }
  }
  .test-content {
    .small {
      font-size: 0.75em;
    }
  }
}

.top-info {
  color: var(--bs-gray-900);
  font-size: $title-size;
  @include max-break-point(small-phone) {
    font-size: 1.2rem;
  }
  .label {
    @include max-break-point(small-phone) {
      margin-top: 0.3125em;
    }
  }
}
