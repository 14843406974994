.job-detail-page {
  .title {
    @extend .fs-5;
  }

  .location-container {
    gap: 0.75rem;
  }

  .location {
    padding: 0.2em 0.5em;
    background-color: var(--bs-bg-color-2);
    border-radius: $border-radius;
  }

  .company-card {
    @extend .bg-dark, .p-4, .text-center;
    border-radius: $border-radius;
    z-index: 2;

    .company-logo {
      object-fit: contain;
    }

    .company-name {
      @extend .fs-5;
    }

    .company-link {
      font-size: $h5-font-size;
      color: var(--bs-secondary-color);
    }

    .company-details {
      max-height: 48.75em;
      overflow: auto;
    }

    ::-webkit-scrollbar {
      width: 0.25em;
    }
  }
}
