$primary-color: #{var(--bs-primary)};
$secondary-color: #{var(--bs-secondary)};
$bg-body: #{var(--bs-bg-color-1)};
$bg-secondary-body: #{var(--bs-bg-color-2)};

$border-color: #{var(--bs-border-color)};

$light: #fff;
$dark: #000;
$light-dark: #848988;

$body-bg: $dark;
// $body-color:$light;

// scss-docs-start border-radius-variables
$border-radius: 0.5rem;
$border-radius-sm: 0.25rem;

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif: "Inter", sans-serif;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;
$mini-font-size: 0.75rem;
$title-font-size: 1.25rem;
$header-font-size: 2.5em;

// stylelint-enable value-keyword-case

$line-height-base: 1.5;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-mid-bold: 600;

$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.375;
$h4-font-size: $font-size-base * 1.125;
$h5-font-size: $font-size-base * 1;
$li-font-size: 0.875rem;

$headings-font-weight: 600;
$headings-color: var(--bs-gray);
$secondary-title-font-size: 1.5rem;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: 0.625rem;
$input-btn-padding-x: 0.625rem;
$input-btn-font-size: 1rem;
$input-btn-line-height: $line-height-base;

$input-btn-padding-y-sm: 0.375rem;
$input-btn-padding-x-sm: 1.25rem;

$input-btn-padding-y-lg: 0.375rem;
$input-btn-padding-x-lg: 1.25rem;

// scss-docs-start input-btn-variable1s
$input-btn-padding-y: 0.625rem;
$input-btn-padding-x: 1rem;
$input-bg: transparent;

$input-color: var(--bs-gray-900);

$input-focus-bg: $input-bg;
$input-focus-border-color: $primary-color;
$input-focus-box-shadow: none;

// scss-docs-start btn-variables
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;

$badge-font-size: 0.8em;

// Accordion

// scss-docs-start accordion-variables
$accordion-padding-y: 1rem !default;
$accordion-padding-x: 1.25rem !default;
// $accordion-color:var(--bs-bg-color-2);
// $accordion-bg: var(--bs-bg-color-2);

// modal
$modal-content-color: var(--bs-gray-900);
$modal-content-bg: var(--bs-bg-color-2);

$progress-bar-color: $bg-body;
$progress-bar-bg: $primary-color;

$text-color: var(--bs-text);

$span-font-size: 0.75em;
$title-size: 0.5rem;

// scss-docs-start form-floating-variables
$form-floating-padding-x: $input-btn-padding-x;
$form-floating-padding-y: $input-btn-padding-y;

$form-floating-height: 3rem;
$form-floating-input-padding-t: 0.4rem;
$form-floating-input-padding-b: 0.625rem;
