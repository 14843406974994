.form {
  &-control {
    background-color: transparent;
    border: 1px solid var(--bs-gray-400);
    border-radius: $border-radius;
    color: var(--bs-gray-800);
    font-size: 0.875em;
    line-height: 1.2;

    &:focus {
      box-shadow: none;
      border-color: rgba(var(--text-secondary-rgb), 60%);
      color: var(--bs-gray-800);
    }
  }

  .group-title {
    font-size: 1em;
    font-weight: $font-weight-semibold;
    color: var(--bs-gray);
  }

  .dropdown .dropdown-toggle {
    font-size: 0.875em;
    color: $text-color;
  }

  .radio-label {
    font-size: 1em;
    font-weight: $font-weight-semibold;
  }

  .form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%272%27 fill=%27%231db27e%27/></svg>");
  }

  .checkbox {
    cursor: pointer;
    height: 1.2em;

    &:focus {
      height: 1.2em;
      border: 0.125em solid $primary-color;
    }

    &:checked,
    &:focus {
      border: 0.125em solid $primary-color;
    }
  }

  .dropdown .dropdown-menu {
    border-radius: $border-radius;
    background-color: $bg-body;
    overflow: auto;
  }

  .dropdown-item {
    p {
      color: var(--bs-gray-600);
      font-weight: $font-weight-normal;
    }
  }
}

.view-more,
.view-less {
  svg {
    font-size: 1rem;
    border: none !important;
    position: relative;
    top: 2px;
  }
}

.box-styled {
  > div {
    border: 0.0625em solid $border-color;
    // border-radius: $inputborder-radius;
    margin-top: 2.5rem;

    &.edit {
      border: 0.0625em solid $primary-color;
    }

    &:first-child {
      @extend .mt-0;
    }
  }

  .d-grid {
    // @include border-radius($inputborder-radius, 0, 0, $inputborder-radius);
    @extend .rounded-start;
    grid-template-columns: auto auto auto;
    grid-gap: 1rem;
    overflow: hidden;
    // padding-bottom: 2rem !important;

    &::after {
      @extend .position-absolute, .start-100, .top-0, .h-100;

      content: "";
      // border-top-right-radius: $inputborder-radius;

      width: 10%;
    }

    > li {
      @extend .position-relative;

      z-index: 2;
    }

    .svg-inline--fa {
      top: 0.3125em !important;
    }
  }

  .content-holder {
    padding: 1rem 1.875rem 3rem 1.875rem;

    ul {
      @extend .m-0, .ps-3;

      li {
        line-height: 1.875em;
        padding-left: 2rem;
      }
    }
  }

  .btn {
    &[aria-expanded="false"] {
      .view-less {
        @extend .d-none;
      }
    }

    &[aria-expanded="true"] {
      .view-more {
        @extend .d-none;
      }
    }
  }
}

.add-section-holder {
  border-radius: $border-radius-sm;
}

.content-holder {
  flex-basis: 100%;
}

.buttons-holder {
  z-index: 2;
}

.editing-btn {
  background: rgba(var(--bs-primary-bg), 50%);
  border: 0.0625em solid $text-color;
  border-radius: $border-radius-sm;
  font-size: $span-font-size;
  line-height: 0.938em;
  color: var(--bs-gray-800);
}

.edit-detail {
  font-size: $h5-font-size;
  line-height: 1.875em;
  color: $light;
}

//TODO: Remove !important
.checkbox-label-container {
  @extend .d-block, .position-relative, .user-select-none, .pe-auto;

  color: $text-color;
  cursor: pointer;
  padding-left: 2.1875rem !important;

  input {
    @extend .position-absolute, .opacity-0;
    @include size(0);

    cursor: pointer;
  }

  .checkmark {
    @extend .position-absolute;
    @extend .top-50;
    @extend .translate-middle-y;

    @include size(1.5em);
    left: -10px;
    border: 0.0625em solid var(--bs-gray-800);
    border-radius: 0.25em !important;
  }

  input:checked ~ .checkmark {
    background-color: $primary-color;
    border: 0.0625em solid $primary-color;

    box-shadow: 0 0 0 5px rgba(var(--bs-primary-rgb), 0.4);

    &::after {
      @extend .d-block;
    }
  }

  .checkmark::after {
    @extend .position-absolute, .d-none, .bg-transparent, .rounded-0;
    @include size(0.875rem, 0.55rem);
    border: solid $dark;
    border-width: 0 0.188em 0.188em 0;
    content: "";
    top: 0.15rem;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
}

.form-check-label {
  top: 12px;
  left: 8px;
}

.portal-content {
  .form-modal-overlay {
    &.overlay {
      background-color: rgba(0, 0, 0, 0.3) !important;

      & > div {
        max-height: 70%;
        overflow: auto !important;
      }
    }
    .card {
      background-color: var(--bs-bg-color-2);
      padding: 1.25rem;
      min-width: 80%;
    }
  }
}
