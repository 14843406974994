.loading-text {
  svg {
    path {
      fill: $text-color;
    }
  }
}

.spinner.loading:before {
  @include size(2.75em);

  content: "";
  position: absolute;
  border: 0.25em solid $text-color;
  border-radius: 100%;
}

.spin:before {
  border-top-color: $primary-color !important;
  border-right-color: $border-color !important;
  border-left-color: $border-color !important;
  border-bottom-color: $border-color !important;
  animation: rotation 0.7s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.spinner-block {
  position: absolute;
  width: 420px;
  height: 80px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loading-button {
  @extend .position-absolute;
  left: -0.25rem;
  top: -0.5rem;
  width: 2.5rem;
  height: 2.5rem;
}
