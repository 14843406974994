.form-block {
  padding: 0 0 1.875rem 0;
}
.input-block > div {
  margin-bottom: 1.875rem;
}

.form-title {
  @extend .d-block, .pb-3, .mb-5, .fw-bold, .fs-2, .text-light;
  border-bottom: 1px solid $border-color;
}

#assessment-container {
  position: absolute;
  left: -9999px;
}
