.progress {
  width: 4.5em;
  height: 4.5em;
  line-height: 4.5em;
  background: none;
  margin: 0 auto;
  box-shadow: none;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 0.575em solid rgba(var(--text-primary-rgb), 10%);
    position: absolute;
    top: 0;
    left: 0;
  }

  & > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .progress-bar {
    background: none;
    border-width: 0.575em;
    border-style: solid;
    top: 0;
    // border-color: $primary-color;
  }

  .progress-left {
    left: 0.0625em;

    .progress-bar {
      left: 100%;
      border-top-right-radius: 5em;
      border-bottom-right-radius: 5em;
      border-left: 0;
      -webkit-transform-origin: center left;
      transform-origin: center left;
      transition: transform 1s;
      transition-delay: 1s;
    }
  }

  .progress-right {
    right: 0;

    .progress-bar {
      left: -100%;
      border-top-left-radius: 5em;
      border-bottom-left-radius: 5em;
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      transition: transform 0.9s;
    }
  }

  .progress-value {
    font-size: $h5-font-size;
    color: $secondary-color;
  }

  .tick-icon {
    color: $primary-color;
    margin-top: 1.4em;
  }
}

.dotted-progressbar {
  .dotted-circle {
    width: 0.62em;
    height: 0.62em;
    background-color: $body-color;
    margin-inline: 0.15em;

    &.filled {
      background-color: $primary-color;
    }
  }
}

.percentage-holder {
  height: 0.5625em;
  background-color: rgba(var(--bs-secondary-rgb), 100%);
}

.light {
  fill: var(--bs-gray-800);
}
