.dashboard-sidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.6fr 2fr 2fr;
  justify-content: center;
  background-color: rgba(var(--bs-body-rgb-1), 0.4);
  width: 16em;
  transition: all 400ms ease;
  border-right: 0.0625rem solid $border-color;
  overflow: visible;
  position: absolute;
  z-index: 100;
  padding-top: 6.5em;

  & + div {
    transition: all 400ms ease;
    padding-left: 16em;

    .header-container {
      transition: all 400ms ease;
      width: calc(100% - 16em);
    }
  }

  &.px-2 + div {
    padding-left: 72px;

    .header-container {
      width: calc(100% - 72px);
    }
  }

  @include max-break-point(tablet-landscape) {
    position: fixed;
    inset: 0;
    z-index: 3;
    width: 0;
    margin-left: -16em;
    z-index: 9999;

    &.mobile-toggle {
      max-width: 16em;
      animation: sidebar 400ms ease-in-out forwards;
      margin-left: 0;
    }
  }

  @keyframes sidebar {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }

  &.icon-container {
    max-width: 4.5em;
  }

  .logo-container {
    cursor: pointer;

    img {
      @extend .w-auto;
    }
  }

  .setting-container {
    border-top: 0.063em solid var(--bs-gray-500);
  }

  .sidebar-tab {
    color: var(--bs-gray-600);
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: $h5-font-size;
    line-height: 1.5em;

    &.active {
      background-color: transparent;
      color: $light;
    }
  }

  .notification-alert {
    // background-color: $danger;
    font-weight: $font-weight-bold;
    font-size: $span-font-size;
    color: $light;
    line-height: 1.2em;
  }

  .nav {
    li {
      border-radius: 0.5em;

      .nav-list,
      .accordion-button {
        border-radius: 0.5em;

        &:hover {
          background-color: $primary-color !important;
          color: $text-color;
        }
      }
    }

    .activeLink {
      background-color: $primary-color !important;
    }

    a.active div {
      background-color: $primary-color;
    }

    .accordion-body {
      &:before {
        content: "";
        position: absolute;
        @include size(6.09em, 0.094em);
        background-color: var(--bs-gray-200);
        top: 0;
        left: 1.2em;
      }
    }

    .accordion-items-wrapper {
      padding-left: 2rem;
      transform-style: preserve-3d;

      &:before {
        content: "";
        position: absolute;
        @include size(0.094em, 0.938em);

        background-color: var(--bs-gray-200);
        top: 50%;
        left: 1.2em;
        transform: translateZ(-0.063em);
      }

      .accordion-list-item {
        border-radius: 0.5em;
        &:hover {
          background-color: $primary-color;
          color: $text-color;
        }
      }
    }
  }
  .accordion-item {
    position: relative;
    overflow: visible;
  }
  .nav-list,
  .accordion-button {
    @extend .d-flex, .align-items-center;
    padding: 0.6875rem 0.8125rem;
    width: 100%;
    height: 54px;
  }

  &.icon-container {
    .nav-list,
    .accordion-button {
      @extend .justify-content-center;

      width: 58px;
      height: 54px;
      margin: 0 auto;
    }

    .nav .accordion-body:before {
      display: none;
    }

    .nav .accordion-items-wrapper:before {
      display: none;
    }
    .accordion-item {
      overflow: visible;
    }
    .accordion-collapse {
      position: absolute;
      right: -240px;
      top: 0;
      width: 230px;
      background-color: rgba(var(--bs-body-rgb-1), 1);
      z-index: 1000;
      .accordion-body {
        padding: 10px !important;
        border-radius: 4px;
      }
      .accordion-items-wrapper {
        padding: 0px !important;
      }
      .accordion-list-item {
        display: block !important;
        padding-left: 0;

        &:hover {
          background-color: $primary-color;
        }
      }
    }
  }
}

.job-sidebar {
  position: fixed;
  top: 0%;
  right: 0%;
  background: var(--bs-bg-color-1);
  justify-content: center;
  width: auto;
  transition: all 0.5s ease-in-out;
  z-index: 99;
  border-left: 0.0625em solid var(--bs-gray-200);

  .title {
    p {
      color: var(--bs-gray-900) !important;
      font-size: 1.1em;
      font-weight: 500;
    }
  }

  .job-info,
  .result {
    .job-title {
      color: var(--bs-gray-900) !important;
      font-size: 14px;
      font-weight: 500;
    }

    .company {
      color: var(--bs-gray-900) !important;
      font-size: 0.7em;
      font-weight: 500;

      span {
        color: #1db27e;
      }
    }

    .switch-job {
      span {
        background-color: var(--bs-bg-color-2);
        color: var(--bs-light);
        border-radius: 0.3em;
        white-space: pre;
      }
    }
  }

  .filter {
    border-bottom: 0.06em solid $body-color;

    input,
    select {
      width: 100%;
    }

    input {
      @include input_placeholder {
        color: var(--bs-gray-600);
      }
    }

    .search-icon {
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      text-decoration: none;
      box-shadow: none;
    }

    .select {
      position: relative;
    }

    label {
      top: 0.7em;
      left: 1em;
      background-color: var(--bs-bg-color-1);
      text-decoration: none;
      position: relative;
      color: var(--bs-gray-600);
    }

    .status {
      background-color: var(--bs-gray-100);
      color: var(--bs-gray-800);
      cursor: pointer;
      font-size: $span-font-size;

      &:focus {
        box-shadow: none;
      }

      &#reset_filter {
        background-color: var(--bs-gray-400);
      }
    }

    .active {
      background-color: var(--bs-primary);
      color: var(--bs-gray-900);
    }
  }

  .result {
    border-bottom: 0.06em solid var(--bs-gray-200);
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }
  }
}

.select {
  div[class*="indicatorContainer"] {
    color: var(--bs-gray-400);
  }

  div[class*="control"]:hover {
    border-color: rgba(var(--text-secondary-rgb), 50%) !important;
  }
}
