.editor-section {
  .input {
    color: var(--bs-gray-800);
    border-radius: 0 0 0.625em 0.625em;
    border-color: $border-color;
    height: 15em;
    overflow-y: auto;
    // background-color: $bg-body;

    @extend .p-4;
  }
  #react-trix-rte-editor {
    border: 0.0625em solid $border-color;
    border-radius: 0.625em 0.625em 0 0;
    box-sizing: border-box;
    background-color: $bg-body;
  }

  .trix-button--icon-bold::before {
    background-image: url("../../images/Bold.svg") !important;
    background-size: 0.9em !important;

    @extend .border-0;
  }

  .trix-button--icon-italic::before {
    background-image: url("../../images/Italic.svg") !important;
    background-size: 0.9em !important;
  }

  .trix-button--icon-underline::before {
    background-image: url("../../images/Underline.svg") !important;
    background-size: 0.8em !important;
  }

  .trix-button--icon-decrease-nesting-level::before {
    filter: invert(1);
  }

  .trix-button--icon-increase-nesting-level::before {
    filter: invert(1);
  }

  .trix-button--icon-bullet-list::before {
    background-image: url("../../images/fi_list.svg") !important;
    background-size: 1.75em !important;
  }

  .trix-button-row {
    margin: 1em;
  }

  .trix-button {
    margin-left: 8px;
    @extend .border-bottom-0;
  }

  .trix-button--icon:disabled::before {
    opacity: 0.2 !important;
    cursor: not-allowed;
  }

  .trix-button--icon[data-trix-active] {
    border-radius: 0.5rem;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .trix-button-group {
    @extend .border-0, .mb-0;
  }

  .trix-button:not(:first-child) {
    @extend .border-0;
  }
}
