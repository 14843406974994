.welcome-page {
  background-color: #0f0f0fe5;
  opacity: 0.9;
  backdrop-filter: blur(1.063em);
  border-radius: 0.625em;
  width: 90%;

  @include min-break-point(mobile-phone) {
    width: 80%;
  }

  @include min-break-point(tablet) {
    width: 40em;
  }

  @include min-break-point(larger-desktop) {
    width: 47em;
  }

  .card {
    background-color: #1a1d1e;
    border-radius: 0.5rem;

    @include min-break-point(tablet) {
      width: 80%;
    }

    @include min-break-point(larger-desktop) {
      width: 66em;
    }
  }

  h1,
  .text-end,
  .text-start,
  .text-center {
    color: var(--bs-gray-900);
  }
}

.modal-header {
  border-bottom: 0.0625em solid var(--bs-gray-900);
}

.workflow-modal-body {
  overflow-y: auto;
  background-color: rgba(var(--bs-body-rgb-2), 100%) !important;

  h5 {
    font-weight: $font-weight-medium;
  }
}
