//media-query
@mixin max-break-point($point) {
  $mobile-phone: 576px;

  $tablet: 768px;

  $tablet-landscape: 991px;

  $large-desktop: 1200px;

  $larger-desktop: 1400px;

  @if $point == mobile-phone {
    @media (max-width: #{$mobile-phone}) {
      @content;
    }
  }

  @if $point == tablet {
    @media (max-width: #{$tablet}) {
      @content;
    }
  }

  @if $point == tablet-landscape {
    @media (max-width:  #{$tablet-landscape}) {
      @content;
    }
  }

  @if $point == large-desktop {
    @media (max-width:#{$large-desktop}) {
      @content;
    }
  }

  @if $point == larger-desktop {
    @media (max-width:#{$larger-desktop}) {
      @content;
    }
  }
}

@mixin min-break-point($point) {
  $mobile-phone: 576px;

  $tablet: 768px;

  $tablet-landscape: 991px;

  $large-desktop: 1200px;

  $larger-desktop: 1400px;

  @if $point == mobile-phone {
    @media (min-width: #{$mobile-phone}) {
      @content;
    }
  }

  @if $point == tablet {
    @media (min-width: #{$tablet}) {
      @content;
    }
  }

  @if $point == tablet-landscape {
    @media (min-width:  #{$tablet-landscape}) {
      @content;
    }
  }

  @if $point == large-desktop {
    @media (min-width:#{$large-desktop}) {
      @content;
    }
  }

  @if $point == larger-desktop {
    @media (min-width:#{$larger-desktop}) {
      @content;
    }
  }
}

//transform
@mixin transform($action) {
  transform: $action;
  transform: $action;
  transform: $action;
  transform: $action;
  transform: $action;
}

@mixin translate-XY($x-axis, $y-axis) {
  transform: translate($x-axis, $y-axis);
  transform: translate($x-axis, $y-axis);
  transform: translate($x-axis, $y-axis);
  transform: translate($y-axis);
  transform: translate($x-axis, $y-axis);
}

@mixin translate-Y($y-axis) {
  transform: translateY($y-axis);
  transform: translateY($y-axis);
  transform: translateY($y-axis);
  transform: translateY($y-axis);
  transform: translateY($y-axis);
}

@mixin translate-X($x-axis) {
  transform: translateX($x-axis);
  transform: translateX($x-axis);
  transform: translateX($x-axis);
  transform: translateX($x-axis);
  transform: translateX($x-axis);
}

// user select

@mixin user-select($value) {
  user-select: $value;
  user-select: $value;
  user-select: $value;
}

//transform
@mixin transform($action) {
  -webkit-transform: $action;
  -moz-transform: $action;
  -o-transform: $action;
  transform: $action;
  -ms-transform: $action;
}

@mixin translate-XY($x-axis, $y-axis) {
  -webkit-transform: translate($x-axis, $y-axis);
  -moz-transform: translate($x-axis, $y-axis);
  -o-transform: translate($x-axis, $y-axis);
  transform: translate($x-axis, $y-axis);
  -ms-transform: translate($y-axis);
}

@mixin translate-Y($y-axis) {
  -webkit-transform: translateY($y-axis);
  -moz-transform: translateY($y-axis);
  -o-transform: translateY($y-axis);
  transform: translateY($y-axis);
  -ms-transform: translateY($y-axis);
}

@mixin translate-X($x-axis) {
  -webkit-transform: translateX($x-axis);
  -moz-transform: translateX($x-axis);
  -o-transform: translateX($x-axis);
  transform: translateX($x-axis);
  -ms-transform: translateX($x-axis);
}

@mixin scale($value) {
  -webkit-transform: scale($value);
  -moz-transform: scale($value);
  -o-transform: scale($value);
  transform: scale($value);
  -ms-transform: scale($value);
}

@mixin scale-X($value) {
  -webkit-transform: scaleX($value);
  -moz-transform: scaleX($value);
  -o-transform: scaleX($value);
  transform: scaleX($value);
  -ms-transform: scaleX($value);
}

@mixin scale-Y($value) {
  -webkit-transform: scaleY($value);
  -moz-transform: scaleY($value);
  -o-transform: scaleY($value);
  transform: scaleY($value);
  -ms-transform: scaleY($value);
}

@mixin size($height, $width: $height) {
  height: $height;
  width: $width;
}

// image url
@mixin image-property {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  transition: 50ms ease-in-out;
  transition-property: background-color, color;
}

//placeholder
@mixin input_placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}


