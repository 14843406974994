.accordion {

  .ribbon {
    margin-bottom: 1em;
    height: 2.8125em;
  }

  .skel-img {
    height: 12em;
    width: 100%;
  }

  .skel-title {
    width: 100%;
  }

  .skel-progress {
    width: 100%;
  }
}
