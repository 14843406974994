.no-course {
  background-color: rgba(var(--bs-body-rgb-2), 100%);
  .no-course-title-container {
    .no-course-title {
      h3 {
        font-size: $h2-font-size;
        font-weight: $font-weight-semibold;
      }
    }
    .no-course-subtitle {
      color: $text-color;
      font-weight: $font-weight-semibold;
      font-size: 1.25em;
    }
  }

  .divider {
    border-bottom: 0.063em solid transparent;
    border-image: linear-gradient(0.25turn, #2d2f2e, $primary-color, #2d2f2e);
    border-image-slice: 1;
  }
}

.react-loading-skeleton {
  --base-color: rgba(var(--bs-body-rgb-1), 100%) !important;
  --highlight-color: rgba(var(--bs-body-rgb-2), 100%) !important;
}
