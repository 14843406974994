.header-container {
  z-index: 101;
  border-bottom: 0.0625rem solid $border-color;

  .logo-container {
    min-width: 1.5em;
  }
}

.logo-container {
  figure {
    @extend .me-0;
  }
  img {
    @extend .w-100;
  }
}

.header-logo {
  &.logo-container {
    img {
      max-width: 9.375rem;
    }
  }
}

.header-container {
  nav {
    ul {
      @extend .flex-row, .pb-0;
    }
    li {
      @extend .px-3;
    }
  }
}

.main-logo {
  object-fit: cover;
}
