@include min-break-point(mobile-phone) {
  .modal-dialog {
    max-width: 50.75em;
  }
}

@include max-break-point(tablet-landscape) {
  .dashboard-sidebar {
    & + div {
      padding-left: 0;
      .header-container {
        width: 100%;
      }
    }
  }
  .dashboard-sidebar {
    &.mobile-toggle {
      background-color: var(--bs-bg-color-1);
      z-index: 9999;
    }
  }
}
