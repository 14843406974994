/** @format */
.theme-switcher-container {
  .form-check-input {
    background-color: rgba(var(--bs-body-rgb-2), 100%);

    &:checked {
      border-color: rgba(var(--bs-body-rgb-2), 100%);
      background-image: url("../../images/dark-mode.svg");
      background-color: rgba(var(--bs-body-rgb-2), 100%);
    }

    &:focus {
      border-color: unset;
      box-shadow: unset;
    }

    &.dark {
      &:checked {
        background-image: url("../../images/dark-mode.svg");
      }
    }

    &.light {
      background-image: url("../../images/light-mode.svg");
      &:focus {
        background-image: url("../../images/light-mode.svg");
      }
    }
  }
  .theme-switcher {
    .toggle-input {
      height: inherit;
      width: inherit;
    }
  }
}

.theme-switcher {
  &.small {
    width: 5.2em;
    height: 2.5em;
  }

  .toggle-input {
    height: inherit;
    width: inherit;
  }

  label {
    position: absolute;
    width: inherit;
    height: inherit;
    top: 0;
    left: 0;
  }

  .dark-mode {
    @include image-property;

    height: inherit;

    &::before,
    &::after {
      @include translate-Y(-50%);

      background: center/1.5em 1.5em no-repeat;
      /* border-radius: 100em; */
      bottom: 0;
      content: "";
      position: absolute;
      top: 60%;
      right: -0.25em;
      width: 3em;
      height: inherit;
    }

    &.--off {
      &::before,
      &::after {
        background-image: url("../../images/dark-mode-off.svg");
      }
    }
  }

  .light-mode {
    @include image-property;

    &::before,
    &::after {
      @include translate-Y(-40%);

      background: center/1.5em 1.5em no-repeat;
      /* border-radius: 100em; */
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 3em;
      height: inherit;
      min-height: 2.9em;
    }

    &.--off {
      &::before,
      &::after {
        background-image: url("../../images/light-mode-off.svg");
      }
    }
  }
}

/** @format */
.no-sidebar {
  .theme-switcher-container {
    .form-check-input {
      background-color: var(--bs-gray-200);

      &:checked {
        border-color: var(--bs-bg-color-1);
        background-color: var(--bs-bg-color-1);
      }
    }
    input:focus ~ label {
      background: initial !important;
    }
  }
}
