.assessment-page-wrap {
  border: 0.0625rem solid $light-dark;

  .assessment {
    gap: 1rem;
  }

  svg {
    path {
      stroke: $white;
    }
  }

  .title {
    color: $white;
    font-size: 1.25rem;
    font-weight: $font-weight-bold;
  }
}
